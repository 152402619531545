.editor-container_classic-editor .editor-container__editor {
  min-width: 100%;
  max-width: 100%;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  padding: 0 2rem;
}

body
  > div.ck-body-wrapper
  > div
  > div.ck.ck-balloon-panel.ck-balloon-panel_position_border-side_right.ck-balloon-panel_visible.ck-powered-by-balloon {
  display: none !important;
}

.ck-content {
  font-family: 'Sora';
  line-height: 1.6;
  word-break: break-word;
  height: 440px;
}

.ck-content ul *,
.ck-content ul,
.ck-content ol *,
.ck-content ol,
.ck-content h1,
.ck-content h2,
.ck-content h3,
.ck-content h4,
.ck-content h5,
.ck-content h6 {
  all: revert;
}

/* Level 1 */
.ck-content ul li {
  list-style-type: disc;
}

.ck-content ol li {
  list-style-type: decimal;
}

/* Level 2 */
.ck-content ul ul li {
  list-style-type: circle;
}

.ck-content ol ul li {
  list-style-type: disc;
}

.ck-content ul ol li {
  list-style-type: decimal;
}

.ck-content ol ol li {
  list-style-type: lower-alpha;
}

/* Level 3 */
.ck-content ul ul ul li {
  list-style-type: square;
}

.ck-content ol ul ul li {
  list-style-type: circle;
}

.ck-content ul ol ul li {
  list-style-type: disc;
}

.ck-content ol ol ul li {
  list-style-type: disc;
}

.ck-content ul ul ol li {
  list-style-type: decimal;
}

.ck-content ol ul ol li {
  list-style-type: decimal;
}

.ck-content ul ol ol li {
  list-style-type: lower-alpha;
}

.ck-content ol ol ol li {
  list-style-type: lower-roman;
}

/* Level 4 */
.ck-content ul ul ul ul li {
  list-style-type: disc;
}

.ck-content ol ul ul ul li {
  list-style-type: square;
}

.ck-content ul ol ul ul li {
  list-style-type: circle;
}

.ck-content ol ol ul ul li {
  list-style-type: circle;
}

.ck-content ul ul ol ul li {
  list-style-type: disc;
}

.ck-content ol ul ol ul li {
  list-style-type: disc;
}

.ck-content ul ol ol ul li {
  list-style-type: disc;
}

.ck-content ol ol ol ul li {
  list-style-type: disc;
}

.ck-content ul ul ul ol li {
  list-style-type: decimal;
}

.ck-content ol ul ul ol li {
  list-style-type: decimal;
}

.ck-content ul ol ul ol li {
  list-style-type: decimal;
}

.ck-content ol ol ul ol li {
  list-style-type: decimal;
}

.ck-content ul ul ol ol li {
  list-style-type: lower-alpha;
}

.ck-content ol ul ol ol li {
  list-style-type: lower-alpha;
}

.ck-content ul ol ol ol li {
  list-style-type: lower-roman;
}

.ck-content ol ol ol ol li {
  list-style-type: decimal;
}

/* Level 5 */
.ck-content ul ul ul ul ul li {
  list-style-type: circle;
}

.ck-content ol ul ul ul ul li {
  list-style-type: disc;
}

.ck-content ul ol ul ul ul li {
  list-style-type: square;
}

.ck-content ol ol ul ul ul li {
  list-style-type: square;
}

.ck-content ul ul ol ul ul li {
  list-style-type: circle;
}

.ck-content ol ul ol ul ul li {
  list-style-type: circle;
}

.ck-content ul ol ol ul ul li {
  list-style-type: circle;
}

.ck-content ol ol ol ul ul li {
  list-style-type: circle;
}

.ck-content ul ul ul ol ul li {
  list-style-type: disc;
}

.ck-content ol ul ul ol ul li {
  list-style-type: disc;
}

.ck-content ul ol ul ol ul li {
  list-style-type: disc;
}

.ck-content ol ol ul ol ul li {
  list-style-type: disc;
}

.ck-content ul ul ol ol ul li {
  list-style-type: disc;
}

.ck-content ol ul ol ol ul li {
  list-style-type: disc;
}

.ck-content ul ol ol ol ul li {
  list-style-type: disc;
}

.ck-content ol ol ol ol ul li {
  list-style-type: disc;
}

.ck-content ul ul ul ul ol li {
  list-style-type: decimal;
}

.ck-content ol ul ul ul ol li {
  list-style-type: decimal;
}

.ck-content ul ol ul ul ol li {
  list-style-type: decimal;
}

.ck-content ol ol ul ul ol li {
  list-style-type: decimal;
}

.ck-content ul ul ol ul ol li {
  list-style-type: decimal;
}

.ck-content ol ul ol ul ol li {
  list-style-type: decimal;
}

.ck-content ul ol ol ul ol li {
  list-style-type: decimal;
}

.ck-content ol ol ol ul ol li {
  list-style-type: decimal;
}

.ck-content ul ul ul ol ol li {
  list-style-type: lower-alpha;
}

.ck-content ol ul ul ol ol li {
  list-style-type: lower-alpha;
}

.ck-content ul ol ul ol ol li {
  list-style-type: lower-alpha;
}

.ck-content ol ol ul ol ol li {
  list-style-type: lower-alpha;
}

.ck-content ul ul ol ol ol li {
  list-style-type: lower-roman;
}

.ck-content ol ul ol ol ol li {
  list-style-type: lower-roman;
}

.ck-content ul ol ol ol ol li {
  list-style-type: decimal;
}

.ck-content ol ol ol ol ol li {
  list-style-type: lower-alpha;
}

/* Level 6 */
.ck-content ul ul ul ul ul ul li {
  list-style-type: square;
}

.ck-content ol ul ul ul ul ul li {
  list-style-type: circle;
}

.ck-content ul ol ul ul ul ul li {
  list-style-type: disc;
}

.ck-content ol ol ul ul ul ul li {
  list-style-type: disc;
}

.ck-content ul ul ol ul ul ul li {
  list-style-type: square;
}

.ck-content ol ul ol ul ul ul li {
  list-style-type: square;
}

.ck-content ul ol ol ul ul ul li {
  list-style-type: square;
}

.ck-content ol ol ol ul ul ul li {
  list-style-type: square;
}

.ck-content ul ul ul ol ul ul li {
  list-style-type: circle;
}

.ck-content ol ul ul ol ul ul li {
  list-style-type: circle;
}

.ck-content ul ol ul ol ul ul li {
  list-style-type: circle;
}

.ck-content ol ol ul ol ul ul li {
  list-style-type: circle;
}

.ck-content ul ul ol ol ul ul li {
  list-style-type: circle;
}

.ck-content ol ul ol ol ul ul li {
  list-style-type: circle;
}

.ck-content ul ol ol ol ul ul li {
  list-style-type: circle;
}

.ck-content ol ol ol ol ul ul li {
  list-style-type: circle;
}

.ck-content ul ul ul ul ol ul li {
  list-style-type: disc;
}

.ck-content ol ul ul ul ol ul li {
  list-style-type: disc;
}

.ck-content ul ol ul ul ol ul li {
  list-style-type: disc;
}

.ck-content ol ol ul ul ol ul li {
  list-style-type: disc;
}

.ck-content ul ul ol ul ol ul li {
  list-style-type: disc;
}

.ck-content ol ul ol ul ol ul li {
  list-style-type: disc;
}

.ck-content ul ol ol ul ol ul li {
  list-style-type: disc;
}

.ck-content ol ol ol ul ol ul li {
  list-style-type: disc;
}

.ck-content ul ul ul ol ol ul li {
  list-style-type: disc;
}

.ck-content ol ul ul ol ol ul li {
  list-style-type: disc;
}

.ck-content ul ol ul ol ol ul li {
  list-style-type: disc;
}

.ck-content ol ol ul ol ol ul li {
  list-style-type: disc;
}

.ck-content ul ul ol ol ol ul li {
  list-style-type: disc;
}

.ck-content ol ul ol ol ol ul li {
  list-style-type: disc;
}

.ck-content ul ol ol ol ol ul li {
  list-style-type: disc;
}

.ck-content ol ol ol ol ol ul li {
  list-style-type: disc;
}

.ck-content ul ul ul ul ul ol li {
  list-style-type: decimal;
}

.ck-content ol ul ul ul ul ol li {
  list-style-type: decimal;
}

.ck-content ul ol ul ul ul ol li {
  list-style-type: decimal;
}

.ck-content ol ol ul ul ul ol li {
  list-style-type: decimal;
}

.ck-content ul ul ol ul ul ol li {
  list-style-type: decimal;
}

.ck-content ol ul ol ul ul ol li {
  list-style-type: decimal;
}

.ck-content ul ol ol ul ul ol li {
  list-style-type: decimal;
}

.ck-content ol ol ol ul ul ol li {
  list-style-type: decimal;
}

.ck-content ul ul ul ol ul ol li {
  list-style-type: decimal;
}

.ck-content ol ul ul ol ul ol li {
  list-style-type: decimal;
}

.ck-content ul ol ul ol ul ol li {
  list-style-type: decimal;
}

.ck-content ol ol ul ol ul ol li {
  list-style-type: decimal;
}

.ck-content ul ul ol ol ul ol li {
  list-style-type: decimal;
}

.ck-content ol ul ol ol ul ol li {
  list-style-type: decimal;
}

.ck-content ul ol ol ol ul ol li {
  list-style-type: decimal;
}

.ck-content ol ol ol ol ul ol li {
  list-style-type: decimal;
}

.ck-content ul ul ul ul ol ol li {
  list-style-type: lower-alpha;
}

.ck-content ol ul ul ul ol ol li {
  list-style-type: lower-alpha;
}

.ck-content ul ol ul ul ol ol li {
  list-style-type: lower-alpha;
}

.ck-content ol ol ul ul ol ol li {
  list-style-type: lower-alpha;
}

.ck-content ul ul ol ul ol ol li {
  list-style-type: lower-roman;
}

.ck-content ol ul ol ul ol ol li {
  list-style-type: lower-roman;
}

.ck-content ul ol ol ul ol ol li {
  list-style-type: lower-roman;
}

.ck-content ol ol ol ul ol ol li {
  list-style-type: lower-roman;
}

.ck-content ul ul ul ol ol ol li {
  list-style-type: decimal;
}

.ck-content ol ul ul ol ol ol li {
  list-style-type: decimal;
}

.ck-content ul ol ul ol ol ol li {
  list-style-type: decimal;
}

.ck-content ol ol ul ol ol ol li {
  list-style-type: decimal;
}

.ck-content ul ul ol ol ol ol li {
  list-style-type: lower-alpha;
}

.ck-content ol ul ol ol ol ol li {
  list-style-type: lower-alpha;
}

.ck-content ul ol ol ol ol ol li {
  list-style-type: lower-roman;
}

.ck-content ol ol ol ol ol ol li {
  list-style-type: decimal;
}
