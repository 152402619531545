@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gray-light font-sora;
}

.template-form-width {
  width: calc(100% - 320px) !important;
}

.hide-number-input-arrows::-webkit-outer-spin-button,
.hide-number-input-arrows::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

td:has(> div.narrow-column) {
  width: 115px;
}

td:has(> div.wider-column) {
  width: 420px;
  max-width: 420px;
}
